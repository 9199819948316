import type { AXAnalyticsConstantsType } from './types';

const CatalogTrackingConstants: AXAnalyticsConstantsType = {
  CatalogView: 'CatalogView'
};

const CatalogSearchTrackingConstants: AXAnalyticsConstantsType = {
  CatalogSearchView: 'CatalogSearchView'
};

const CatalogFilterTrackingConstants: AXAnalyticsConstantsType = {
  CatalogFiltersApplied: 'CatalogFiltersApplied'
};

const CatalogSearchFilterTrackingConstants: AXAnalyticsConstantsType = {
  CatalogSearchFiltersApplied: 'CatalogSearchFiltersApplied'
};

const AvatarEditorTrackingConstants: AXAnalyticsConstantsType = {
  AvatarEditorView: 'AvatarEditorView'
};

const CatalogItemDetailsTrackingConstants: AXAnalyticsConstantsType = {
  CatalogItemDetailsView: 'CatalogItemDetailsView',
  PurchaseSuccessAsset: 'PurchaseSuccessAsset',
  PurchaseSuccessBundle: 'PurchaseSuccessBundle'
};

const AXAnalyticsConstants: AXAnalyticsConstantsType = {
  ...CatalogTrackingConstants,
  ...CatalogSearchTrackingConstants,
  ...CatalogFilterTrackingConstants,
  ...CatalogSearchFilterTrackingConstants,
  ...AvatarEditorTrackingConstants,
  ...CatalogItemDetailsTrackingConstants
};

export default AXAnalyticsConstants;
